import React from "react";

const Services8 = () => {
  return (
    <section className="app-services section-padding bg-gray">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-10">
            <div className="s-head text-center mb-80">
              <h6 className="stit mb-30">
                <span className="left"></span> My IT Toolkit
                <span className="right"></span>
              </h6>
              <h2>Here are the top skills that have been instrumental throughout my IT career:</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="item mb-30">
              <div className="item-tit mb-15">
                <div className="icon">
                  <span className="pe-7s-cloud-upload"></span>
                </div>
                <div className="text-tit">
                  <h5>Clouds Everywhere</h5>
                </div>
              </div>

              <p>
                
The power of cloud services has revolutionized IT support pipelines, minimizing the need for on-site hardware and enabling global accessibility.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="item mb-30">
              <div className="item-tit mb-15">
                <div className="icon">
                  <span className="pe-7s-rocket"></span>
                </div>
                <div className="text-tit">
                  <h5>10GbE Networking</h5>
                </div>
              </div>

              <p>
              I have a need for speed. 10GbE enhances collaboration speeds, which is particularly crucial for video editing workflows.              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="item mb-30">
              <div className="item-tit mb-15">
                <div className="icon">
                  <span className="pe-7s-plugin"></span>
                </div>
                <div className="text-tit">
                  <h5>Computer Assembly</h5>
                </div>
              </div>

              <p>
              For CG rendering computers, I have always personally assembled them, meticulously selecting the best components to handle high workloads efficiently.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="item sm-mb50">
              <div className="item-tit mb-15">
                <div className="icon">
                  <span className="pe-7s-light"></span>
                </div>
                <div className="text-tit">
                  <h5>Cyber-savvy Users</h5>
                </div>
              </div>

              <p>
               
The best defense against cyber attacks is an informed user. I created engaging, short videos to educate and inform the user base about cyber threats.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="item sm-mb50">
              <div className="item-tit mb-15">
                <div className="icon">
                  <span className="pe-7s-graph1"></span>
                </div>
                <div className="text-tit">
                  <h5>Always Updating</h5>
                </div>
              </div>

              <p>
              Navigating the perpetual dance of juggling security, stability, and keeping the business humming along smoothly demands constant updates and testing.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="item">
              <div className="item-tit mb-15">
                <div className="icon">
                  <span className="pe-7s-glasses"></span>
                </div>
                <div className="text-tit">
                  <h5>Problem-solving</h5>
                </div>
              </div>

              <p>
              The ability to methodically analyze and break down complex problems into manageable tasks is crucial for finding effective solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services8;
